<template>
  <div class="goods-order-return">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user' }">会员中心</el-breadcrumb-item>
      <el-breadcrumb-item>商品退换</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <h3>商品退换</h3>
      请完整填下表，并获取RMA号。
      <h4>订单信息</h4>
      <el-divider></el-divider>
      <el-form label-width="120px">
        <el-form-item label="名字">
          <el-input placeholder="名字"></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱">
          <el-input placeholder="电子邮箱"></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input placeholder="电话"></el-input>
        </el-form-item>
        <el-form-item label="订单号">
          <el-input placeholder="订单号"></el-input>
        </el-form-item>
        <el-form-item label="订单日期">
          <el-date-picker
            type="date"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <h4>商品信息 & 退换原因</h4>
      <el-divider></el-divider>
      <el-form label-width="120px">
        <el-form-item label="商品名称">
          <el-input placeholder="商品名称"></el-input>
        </el-form-item>
        <el-form-item label="商品型号">
          <el-input placeholder="商品型号"></el-input>
        </el-form-item>
        <el-form-item label="数量">
          <el-input type="number"></el-input>
        </el-form-item>
        <el-form-item label="退换原因">
          <el-radio-group>
            <el-radio label="其他，请添加描述"></el-radio>
            <el-radio label="发错商品"></el-radio>
            <el-radio label="商品损坏，请添加描述"></el-radio>
            <el-radio label="未收到货物"></el-radio>
            <el-radio label="错误下单"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="已打开包装">
          <el-radio-group>
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="详细描述">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="详细描述">
          </el-input>
        </el-form-item>
        <el-checkbox>我已阅读并同意<a href="#">隐私策略</a></el-checkbox>
        <el-button>提交</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'goodsOrderReturn'
}
</script>

<style scoped lang="scss">
@import "src/scss/index";
.goods-order-return {
  @include body;
}
</style>
